$theme-colors: (
  "primary":    #1E2946,
  "secondary":  #adaeaf,
  "success":    #179d37,
  "info":       #17a2b8,
  "warning":    #ffc107,
  "danger":     #d61f31,
  "light":      #f8f9fa,
  "dark":       #343a40
);

$font-family-sans-serif: 'Poppins', sans-serif;

@import '~bootstrap/scss/bootstrap';

.page-content {
  margin-left: 100px;
  padding: 16px;
}

.editGrade {
  border: 2px solid black;
}

.mobile-content {
  margin-left: 0px;
  padding: 16px;
}

.background {
  background-image: url('./assets/images/blue-wavy-background.jpg');
  background-size: cover;
  background-repeat: repeat;
  background-attachment: fixed;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

@media (min-width: 576px) {
  .modal-90w {
      max-width: 90%;
  }
}

/* ------------------ */
/* CSS for snowflakes */
/*   To remove asap   */

.snowflake {
  color: #fff;
  font-size: 1em;
  font-family: Arial, sans-serif;
  text-shadow: 0 0 5px #000;
}

@keyframes snowflakes-fall {
  0% {
    transform: translateY(0vh);
  }

  100% {
    transform: translateY(110vh);
  }
}

@keyframes snowflakes-shake {
  0% {
    transform: translateX(0px);
  }

  50% {
    transform: translateX(80px);
  }

  100% {
    transform: translateX(0px);
  }
}

.snowflake {
  position: fixed;
  top: -10%;
  z-index: 9999;
  /* still needed for Safari */
  -webkit-user-select: none;
  user-select: none;
  cursor: default;

  animation-name: snowflakes-shake;
  animation-duration: 3s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-play-state: running;
}

.snowflake .inner {
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-name: snowflakes-fall;
  animation-play-state: running;
  animation-timing-function: linear;
}

.snowflake:nth-of-type(0) {
  left: 1%;
  animation-delay: 0s;
}
.snowflake:nth-of-type(0) .inner {
  animation-delay: 0s;
}

.snowflake:nth-of-type(1) {
  left: 10%;
  animation-delay: 1s;
}
.snowflake:nth-of-type(1) .inner {
  animation-delay: 1s;
}

.snowflake:nth-of-type(2) {
  left: 20%;
  animation-delay: 0.5s;
}
.snowflake:nth-of-type(2) .inner {
  animation-delay: 6s;
}

.snowflake:nth-of-type(3) {
  left: 30%;
  animation-delay: 2s;
}
.snowflake:nth-of-type(3) .inner {
  animation-delay: 4s;
}

.snowflake:nth-of-type(4) {
  left: 40%;
  animation-delay: 2s;
}
.snowflake:nth-of-type(4) .inner {
  animation-delay: 2s;
}

.snowflake:nth-of-type(5) {
  left: 50%;
  animation-delay: 3s;
}
.snowflake:nth-of-type(5) .inner {
  animation-delay: 8s;
}

.snowflake:nth-of-type(6) {
  left: 60%;
  animation-delay: 2s;
}
.snowflake:nth-of-type(6) .inner {
  animation-delay: 6s;
}

.snowflake:nth-of-type(7) {
  left: 70%;
  animation-delay: 1s;
}
.snowflake:nth-of-type(7) .inner {
  animation-delay: 2.5s;
}

.snowflake:nth-of-type(8) {
  left: 80%;
  animation-delay: 0s;
}
.snowflake:nth-of-type(8) .inner {
  animation-delay: 1s;
}

.snowflake:nth-of-type(9) {
  left: 90%;
  animation-delay: 1.5s;
}
.snowflake:nth-of-type(9) .inner {
  animation-delay: 3s;
}

.snowflake:nth-of-type(10) {
  left: 25%;
  animation-delay: 0s;
}
.snowflake:nth-of-type(10) .inner {
  animation-delay: 2s;
}

.snowflake:nth-of-type(11) {
  left: 65%;
  animation-delay: 2.5s;
}
.snowflake:nth-of-type(11) .inner {
  animation-delay: 4s;
}

/* ------------------ */
